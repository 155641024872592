import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

const Error = () => {
  return (
    <>
    <Helmet
      htmlAttributes={{
          class: 'bd-main'
      }}>
          <title>404: You Might Be Lost</title>
    </Helmet>
      
      <h1>404: Page Not Found</h1>
      <p>You might be lost. Try to find your way from our <Link to='/'>home page</Link>.</p>
      
    </>
  )
}

export default Error